import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { Table, Divider, notification, Spin } from "antd";
import { CaretLeftOutlined, CaretRightOutlined } from "@ant-design/icons";

import { BASE_API_PATH, COLUMNS, CURRENCIES } from "./constants";
import Logo from "./logo";

var BBSkyAddinClient = require("@blackbaud/sky-addin-client");

function App() {
  const [data, setData] = useState();
  const [activities, setActivities] = useState([]);
  const [totalActivities, setTotalActivities] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [environmentId, setEnvironmentId] = useState("");
  const [systemRecordId, setSystemRecordId] = useState("");
  const [summaryToken, setSummaryToken] = useState("");
  const [loading, setLoading] = useState(true);
  const [loadingSummary, setLoadingSummary] = useState(true);
  const [notFound, setNotfound] = useState(false);

  const getSummaryToken = useCallback(
    async (environmentId, systemRecordId, args) => {
      try {
        setLoadingSummary(true);
        const res = await axios.options(
          `${BASE_API_PATH}/activity/api/platform/public?environment_id=${environmentId}&record_id=${systemRecordId}`,
          {}
        );
        const token = res.data.data?.token;
        setSummaryToken(token);
        fetchData(environmentId, systemRecordId, token);
        fetchTableData(environmentId, systemRecordId, currentPage, token);
        args.ready({
          showUI: true,
          title: "Engagement history by Almabase",
        });
      } catch (err) {
        setLoadingSummary(false);
      }
    },
    [currentPage]
  );

  useEffect(() => {
    new BBSkyAddinClient.AddinClient({
      callbacks: {
        init: function (args) {
          const systemRecordId = args.context.recordId;
          const { envId } = args;
          setSystemRecordId(systemRecordId);
          setEnvironmentId(envId);
          getSummaryToken(envId, systemRecordId, args);
        },
      },
    });
  }, [getSummaryToken]);

  async function fetchData(environmentId, systemRecordId, token) {
    try {
      const res = await axios.get(
        `${BASE_API_PATH}/activity/api/platform/public?environment_id=${environmentId}&record_id=${systemRecordId}&token=${token}`,
        { timeout: 120000 }
      );
      setData(res.data?.data);
      setLoadingSummary(false);
    } catch (err) {
      setLoadingSummary(false);
      if (err.response?.status === 404) {
        setNotfound(true);
      }
    }
  }

  async function fetchTableData(
    environmentId,
    systemRecordId,
    page = 1,
    token
  ) {
    try {
      setLoading(true);
      const res = await axios.get(
        `${BASE_API_PATH}/api/v1/almasights/engagement/public/?environment_id=${environmentId}&record_id=${systemRecordId}&token=${token}&ordering=-recorded_at&fields=id,recorded_at,touchpoint_group__touchpoint_type__id,touchpoint_group__touchpoint_type__name,touchpoint_group__parent_object,touchpoint_group__touchpoint_type__description&page=${page}`,
        { timeout: 120000 }
      );
      setTotalActivities(res.data.count);
      setActivities(res.data.results);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error.response?.status !== 404) {
        notification.error({ message: "Please try refreshing the page" });
      }
    }
  }

  function handlePageChange(page) {
    setCurrentPage(page);
    fetchTableData(environmentId, systemRecordId, page, summaryToken);
  }

  function renderTableItems(_, type, originalElement) {
    if (type === "prev") {
      return <CaretLeftOutlined />;
    }
    if (type === "next") {
      return <CaretRightOutlined />;
    }
    return originalElement;
  }

  function getNumberWithCurrency(amount = 22, currency = "USD") {
    const currencyObj = CURRENCIES.filter(
      (currencyL) => currencyL.iso_code === currency
    )[0];

    return (
      <span>
        <span
          dangerouslySetInnerHTML={{
            __html: currencyObj?.html_entity,
          }}
        />
        {amount?.toLocaleString()}
      </span>
    );
  }

  function getGiftsCount(gifts) {
    let total = 0;
    gifts?.forEach((gift) => {
      if (gift.total_donated) {
        total += gift.total_donated;
      }
    });
    return total;
  }

  function renderData() {
    if (loadingSummary) {
      return (
        <div className="loading-parent-wrapper">
          <Spin />
        </div>
      );
    } else if (notFound) {
      return (
        <p className="text-light">
          Engagement history not found. This information is only available for
          constituents currently in Almabase.
        </p>
      );
    }

    return (
      <div className="parent-container">
        <div className="parent-wrapper">
          <p className="text-light text-size-regular">
            This is based on the constituent's engagement history on Almabase
            over the last 365 days
          </p>
          <div className="module-container">
            <p className="text-size-heading-3">Communication</p>
            <div className="stats-container">
              <div>
                <p className="text-size-display-3">
                  {data?.communication?.average_open_rate || 0}%
                </p>
                <p className="text-size-regular text-light">
                  Average open rate
                </p>
              </div>
              <div>
                <p className="text-size-display-3">
                  {data?.communication?.average_click_rate || 0}%
                </p>
                <p className="text-size-regular text-light">
                  Average click rate
                </p>
              </div>
              <div>
                <p className="text-emphasized">Last email opened</p>
                <p className="text-size-regular text-italic text-light">
                  {data?.communication?.last_open_email_name ||
                    "No email opened"}
                </p>
              </div>
              <div>
                <p className="text-emphasized">Last link clicked in an email</p>
                <p className="text-size-regular text-italic text-light">
                  {data?.communication?.last_click_email_name ||
                    "No email clicked"}
                </p>
              </div>
            </div>
          </div>

          <div className="module-container">
            <p className="text-size-heading-3">Events</p>
            {data?.event?.enabled === false ? (
              <div className="disabled-module-container">
                <div>
                  <a
                    href="https://www.almabase.com/products/events?utm_source=RENXT+Engagement+tile&utm_medium=Product&utm_campaign=cross-sell"
                    target="_blank"
                    rel="noreferrer"
                    className="link"
                  >
                    Click here
                  </a>{" "}
                  to enable Almabase Events to view communication data of a
                  constituent
                </div>
              </div>
            ) : (
              <div className="stats-container">
                <div>
                  <p className="text-size-display-3">
                    {data?.event?.data[0]?.event[0]?.total_registered || 0}
                  </p>
                  <p className="text-size-regular text-light">
                    Event registrations
                  </p>
                </div>

                {data?.event?.rating ? (
                  <div>
                    <p className="text-size-display-3">
                      {data?.event?.rating || "No ratings"}
                    </p>
                    <p className="text-size-regular text-light">
                      Average rating
                    </p>
                  </div>
                ) : null}

                <div>
                  <p className="text-emphasized">Last event registered</p>
                  <p className="text-size-regular text-light text-italic">
                    {data?.event?.data[0]?.event[0]?.last_registered?.name
                      ? data?.event?.data[0]?.event[0]?.last_registered?.name
                      : "No events attended"}
                  </p>
                </div>

                {data?.event?.feedback?.rating ? (
                  <div>
                    <p className="text-emphasized">
                      Last registration feedback
                    </p>
                    <p className="text-size-regular text-light text-italic">
                      {data?.event?.feedback?.rating
                        ? `${data?.event?.feedback?.rating}/5|`
                        : null}{" "}
                      "
                      {data?.event?.feedback?.comment ||
                        "No feedback submitted"}
                      "
                    </p>
                  </div>
                ) : null}
              </div>
            )}
          </div>

          <div className="module-container">
            <p className="text-size-heading-3">Giving</p>
            {data?.giving?.enabled === false ? (
              <div className="disabled-module-container">
                <div>
                  <a
                    href="https://www.almabase.com/products/crowdfunding?utm_source=RENXT+Engagement+tile&utm_medium=Product&utm_campaign=cross-sell"
                    target="_blank"
                    rel="noreferrer"
                    className="link"
                  >
                    Click here
                  </a>{" "}
                  to enable Almabase Giving to view communication data of a
                  constituent
                </div>
              </div>
            ) : (
              <div className="stats-container">
                <div>
                  <p className="text-size-display-3">
                    {data?.giving?.data
                      ? getGiftsCount(data?.giving?.data[0]?.gift)
                      : 0}
                  </p>
                  <p className="text-size-regular text-light">Gifts made</p>
                </div>
                <div>
                  <div>
                    {data?.giving?.data &&
                    data?.giving?.data[0]?.total_amount?.length ? (
                      data?.giving?.data[0]?.total_amount?.map(
                        (gift, index) => (
                          <span>
                            <span className="text-size-display-3">
                              {getNumberWithCurrency(
                                gift?.amount,
                                gift?.currency
                              )}
                            </span>
                            {index !==
                            data?.giving?.data[0]?.total_amount?.length - 1 ? (
                              <span className="text-size-display-3"> | </span>
                            ) : null}
                          </span>
                        )
                      )
                    ) : (
                      <span className="text-size-display-3">0</span>
                    )}
                  </div>
                  <p className="text-size-regular text-light">
                    Total gift amount
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
        <Divider />
        <div className="engagement-table-container">
          <div>
            <span>
              <span className="text-size-display-3">{totalActivities}</span>{" "}
              <span className="text-light">recent engagement activities</span>
            </span>
          </div>
          {!loading && activities?.length === 0 ? (
            <div className="no-activity-container">
              <p className="text-light text-italic">
                No engagement information found
              </p>
            </div>
          ) : (
            <Table
              bordered
              columns={COLUMNS}
              loading={loading}
              pagination={{
                position: ["bottomLeft"],
                total: totalActivities,
                current: currentPage,
                showSizeChanger: false,
                onChange: handlePageChange,
                itemRender: renderTableItems,
              }}
              dataSource={activities}
            />
          )}
        </div>
        <div className="logo-container">
          <p>Powered by</p>
          <Logo />
        </div>
      </div>
    );
  }

  return <>{renderData()}</>;
}

export default App;
