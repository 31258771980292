export const BASE_API_PATH = "https://almanac.almabase.com";

export let CURRENCIES = [
  {
    id: 1,
    iso_code: "USD",
    html_entity: "&#x24;",
  },
  {
    id: 2,
    iso_code: "INR",
    html_entity: "&#x20B9;",
  },
  {
    id: 3,
    iso_code: "EUR",
    html_entity: "&#x20AC;",
  },
  {
    id: 4,
    iso_code: "AED",
    html_entity: "AED",
  },
  {
    id: 5,
    iso_code: "GBP",
    html_entity: "&#xa3;",
  },
  {
    id: 6,
    iso_code: "PHP",
    html_entity: "&#x20B1;",
  },
  {
    id: 7,
    iso_code: "CHF",
    html_entity: "U+020A3",
  },
  {
    id: 8,
    iso_code: "SAR",
    html_entity: "&#xFDFC;",
  },
  {
    id: 9,
    iso_code: "MYR",
    html_entity: "RM;",
  },
  {
    id: 10,
    iso_code: "AUD",
    html_entity: "AU&#x24;;",
  },
  {
    id: 11,
    iso_code: "CAD",
    html_entity: "C$",
  },
];

export const COLUMNS = [
  {
    title: "Activity",
    dataIndex: "id",
    key: "id",
    render: (_, record) => (
      <div>
        <p className="text-size-regular">
          {record?.touchpoint_group?.touchpoint_type?.name}
        </p>
        <a
          href={record?.touchpoint_group?.parent_object?.url}
          className="text-emphasized link"
          target="_blank"
          rel="noreferrer"
        >
          {record?.touchpoint_group?.parent_object?.name}
        </a>
      </div>
    ),
  },
  {
    title: "Date",
    dataIndex: "recorded_at",
    key: "recorded_at",
    width: 230,
    render: (value) => (
      <p className="text-size-regular">
        {new Date(value).toLocaleDateString()}
      </p>
    ),
  },
];
