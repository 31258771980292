export default function Logo() {
  return (
    <svg
      width="60"
      height="18"
      viewBox="0 0 60 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.23971 6.05829C4.53348 6.1778 4.0011 6.41682 3.65342 6.77264C3.30302 7.13117 3.12646 7.58205 3.12646 8.1117C3.12646 8.64135 3.33018 9.11396 3.7349 9.45619C4.13419 9.79571 4.66115 9.96955 5.29946 9.96955C5.75851 9.96955 6.18496 9.87991 6.56252 9.70608C6.87761 9.55941 7.14651 9.36656 7.3611 9.13026V9.82831H8.56168V5.47975C8.56168 5.04788 8.45303 4.65675 8.24116 4.31995C8.02929 3.98315 7.72779 3.71696 7.34752 3.52955C6.96996 3.34214 6.53264 3.24707 6.04915 3.24707C5.47874 3.24707 4.94364 3.39646 4.45743 3.6898C3.97122 3.98586 3.63169 4.36069 3.44698 4.80886L3.42254 4.8659L4.49002 5.41999L4.5199 5.35209C4.64485 5.06146 4.84313 4.82515 5.10933 4.65132C5.3728 4.47749 5.68246 4.38785 6.0247 4.38785C6.41313 4.38785 6.73093 4.49107 6.97539 4.69478C7.21985 4.89849 7.33665 5.15381 7.33665 5.47975V5.71334L5.24242 6.05557L5.23971 6.05829ZM7.33393 7.08499C7.33393 7.4218 7.25245 7.73415 7.09219 8.00848C6.93193 8.28553 6.70376 8.50554 6.41584 8.66851C6.12792 8.82876 5.79382 8.91025 5.42441 8.91025C5.13106 8.91025 4.88388 8.82876 4.69102 8.66851C4.49817 8.50826 4.40582 8.31541 4.40582 8.07639C4.40582 7.52501 4.76708 7.19364 5.5059 7.0687L7.33393 6.7672V7.08499Z"
        fill="#004BC8"
      />
      <path
        d="M11.3296 0.84082H10.1073V9.83128H11.3296V0.84082Z"
        fill="#004BC8"
      />
      <path
        d="M14.103 6.03113C14.103 5.54494 14.2361 5.15381 14.4995 4.8659C14.763 4.5807 15.0998 4.43675 15.5073 4.43675C15.9147 4.43675 16.2597 4.57799 16.5096 4.85775C16.7622 5.14023 16.8898 5.53407 16.8898 6.02841V9.82831H18.1257V6.02841C18.1257 5.54222 18.2588 5.14838 18.5169 4.86318C18.7749 4.57799 19.1172 4.43403 19.53 4.43403C19.9429 4.43403 20.2716 4.57527 20.5269 4.85503C20.7822 5.13751 20.9126 5.53135 20.9126 6.02569V9.82559H22.1485V5.67531C22.1485 5.22171 22.0398 4.80343 21.8225 4.43675C21.608 4.07006 21.3119 3.77672 20.9452 3.56486C20.5758 3.353 20.1683 3.24707 19.731 3.24707C19.2937 3.24707 18.8999 3.35028 18.544 3.554C18.2208 3.73869 17.9546 3.99944 17.7536 4.32538C17.5635 4.01303 17.3027 3.75771 16.974 3.56758C16.6101 3.35572 16.2026 3.24979 15.7653 3.24979C15.3823 3.24979 15.0265 3.33399 14.7114 3.49696C14.4615 3.62733 14.2496 3.80388 14.0785 4.02117V3.38831H12.8779V9.82831H14.1002V6.02841L14.103 6.03113Z"
        fill="#004BC8"
      />
      <path
        d="M25.416 6.05829C24.7098 6.1778 24.1774 6.41682 23.8297 6.77264C23.4793 7.13117 23.3027 7.58205 23.3027 8.1117C23.3027 8.64135 23.5065 9.11396 23.9112 9.45619C24.3105 9.79571 24.8374 9.96955 25.4757 9.96955C25.9348 9.96955 26.3612 9.87991 26.7388 9.70608C27.0539 9.55941 27.3228 9.36656 27.5374 9.13026V9.82831H28.738V5.47975C28.738 5.04788 28.6293 4.65675 28.4174 4.31995C28.2056 3.98315 27.9041 3.71696 27.5238 3.52955C27.1462 3.34214 26.7089 3.24707 26.2254 3.24707C25.655 3.24707 25.1199 3.39646 24.6337 3.6898C24.1475 3.98586 23.808 4.36069 23.6233 4.80886L23.5988 4.8659L24.6663 5.41999L24.6962 5.35209C24.8211 5.06146 25.0194 4.82515 25.2856 4.65132C25.5491 4.47749 25.8587 4.38785 26.201 4.38785C26.5894 4.38785 26.9072 4.49107 27.1517 4.69478C27.3961 4.89849 27.5129 5.15381 27.5129 5.47975V5.71334L25.4187 6.05557L25.416 6.05829ZM27.5102 7.08499C27.5102 7.4218 27.4287 7.73415 27.2685 8.00848C27.1082 8.28553 26.88 8.50554 26.5921 8.66851C26.3042 8.82876 25.9701 8.91025 25.6007 8.91025C25.3073 8.91025 25.0601 8.82876 24.8673 8.66851C24.6744 8.50826 24.5821 8.31541 24.5821 8.07639C24.5821 7.52501 24.9433 7.19364 25.6822 7.0687L27.5102 6.7672V7.08499Z"
        fill="#004BC8"
      />
      <path
        d="M31.4841 9.04631C31.7123 9.31521 31.9948 9.52979 32.3234 9.68733C32.7119 9.87474 33.1492 9.96981 33.6164 9.96981C34.2194 9.96981 34.7708 9.82042 35.2543 9.52707C35.7378 9.23373 36.1235 8.82902 36.4005 8.3211C36.6776 7.81318 36.8188 7.24007 36.8188 6.61536C36.8188 5.99064 36.6776 5.41482 36.4005 4.90418C36.1235 4.39355 35.7378 3.98612 35.2543 3.69006C34.7708 3.39672 34.2221 3.24733 33.6272 3.24733C33.1682 3.24733 32.739 3.33696 32.3533 3.51623C32.0247 3.66833 31.7422 3.87205 31.5086 4.12465V0.84082H30.2863V9.83128H31.4868V9.04903L31.4841 9.04631ZM31.7748 5.50174C31.9513 5.17037 32.1958 4.90962 32.5054 4.71949C32.8124 4.53207 33.16 4.43701 33.5349 4.43701C33.9097 4.43701 34.252 4.53207 34.5508 4.71949C34.8523 4.9069 35.0913 5.17037 35.2624 5.50174C35.4363 5.83311 35.5232 6.20794 35.5232 6.61536C35.5232 7.02278 35.4363 7.39761 35.2679 7.72355C35.0994 8.04949 34.8604 8.31024 34.5562 8.49765C34.252 8.68507 33.9097 8.78013 33.5349 8.78013C33.16 8.78013 32.8124 8.68507 32.5054 8.49765C32.1985 8.31024 31.9513 8.04949 31.7748 7.72355C31.5982 7.39489 31.5086 7.02278 31.5086 6.61536C31.5086 6.20794 31.5982 5.83311 31.7748 5.50174Z"
        fill="#004BC8"
      />
      <path
        d="M39.9642 6.05829C39.258 6.1778 38.7256 6.41682 38.3779 6.77264C38.0275 7.13117 37.851 7.58205 37.851 8.1117C37.851 8.64135 38.0547 9.11396 38.4594 9.45619C38.8587 9.79571 39.3856 9.96955 40.024 9.96955C40.483 9.96955 40.9095 9.87991 41.287 9.70608C41.6021 9.55941 41.871 9.36656 42.0856 9.13026V9.82831H43.2862V5.47975C43.2862 5.04788 43.1775 4.65675 42.9656 4.31995C42.7538 3.98315 42.4523 3.71696 42.072 3.52955C41.6944 3.34214 41.2571 3.24707 40.7736 3.24707C40.2032 3.24707 39.6681 3.39646 39.1819 3.6898C38.6957 3.98586 38.3562 4.36069 38.1715 4.80886L38.147 4.8659L39.2145 5.41999L39.2444 5.35209C39.3693 5.06146 39.5676 4.82515 39.8338 4.65132C40.0973 4.47749 40.4069 4.38785 40.7492 4.38785C41.1376 4.38785 41.4554 4.49107 41.6999 4.69478C41.9443 4.89849 42.0611 5.15381 42.0611 5.47975V5.71334L39.9669 6.05557L39.9642 6.05829ZM42.0584 7.08499C42.0584 7.4218 41.9769 7.73415 41.8167 8.00848C41.6564 8.28553 41.4283 8.50554 41.1403 8.66851C40.8524 8.82876 40.5183 8.91025 40.1489 8.91025C39.8555 8.91025 39.6084 8.82876 39.4155 8.66851C39.2227 8.50826 39.1303 8.31541 39.1303 8.07639C39.1303 7.52501 39.4916 7.19364 40.2304 7.0687L42.0584 6.7672V7.08499Z"
        fill="#004BC8"
      />
      <path
        d="M47.996 8.66503C47.7814 8.81985 47.5044 8.89862 47.1703 8.89862C46.8199 8.89862 46.4885 8.7954 46.1897 8.59441C45.8882 8.3907 45.6492 8.10822 45.4753 7.75512L45.4454 7.69265L44.4513 8.19514L44.473 8.25217C44.6795 8.77911 45.0271 9.20011 45.5052 9.50704C45.9805 9.81396 46.5374 9.96878 47.1567 9.96878C47.6157 9.96878 48.0313 9.8873 48.3899 9.72161C48.7511 9.55864 49.0363 9.32777 49.2373 9.03714C49.4383 8.74651 49.5415 8.40971 49.5415 8.04031C49.5415 7.58128 49.3894 7.17115 49.0852 6.82076C48.7864 6.4731 48.3627 6.22321 47.8194 6.07925L46.7818 5.94073C46.5754 5.90542 46.3853 5.81307 46.2114 5.66911C46.043 5.53059 45.9615 5.34589 45.9615 5.10687C45.9615 4.86785 46.0485 4.69673 46.2277 4.55278C46.4097 4.4061 46.6542 4.33277 46.9584 4.33277C47.2789 4.33277 47.575 4.4224 47.8385 4.60167C48.1046 4.78093 48.3138 5.0281 48.4632 5.33503L48.4931 5.3975L49.4736 4.8923L49.4519 4.83526C49.2591 4.34092 48.9385 3.94707 48.4958 3.66731C48.053 3.38755 47.5478 3.24902 46.991 3.24902C46.57 3.24902 46.1815 3.33051 45.8339 3.48804C45.4835 3.6483 45.2091 3.87645 45.0108 4.16708C44.8126 4.45771 44.7121 4.79723 44.7121 5.17477C44.7121 5.6338 44.8587 6.03851 45.1494 6.37803C45.4373 6.71483 45.8746 6.95929 46.4559 7.10324L47.4039 7.2309C47.6239 7.26078 47.833 7.35313 48.0259 7.50252C48.2133 7.64919 48.3029 7.8366 48.3029 8.07562C48.3029 8.31465 48.2024 8.51292 47.9933 8.66503H47.996Z"
        fill="#004BC8"
      />
      <path
        d="M52.1277 9.52724C52.6193 9.82058 53.1735 9.96997 53.7765 9.96997C54.4094 9.96997 54.9798 9.81787 55.4741 9.51638C55.9658 9.21488 56.3216 8.82647 56.5307 8.36473L56.5579 8.30497L55.5312 7.77804L55.4986 7.83236C55.0938 8.5114 54.537 8.84005 53.79 8.84005C53.4451 8.84005 53.1218 8.76129 52.8339 8.60375C52.546 8.44621 52.307 8.22349 52.1277 7.93829C51.9593 7.67211 51.8615 7.35704 51.8371 7.00122H56.6394L56.6503 6.9469C56.691 6.73232 56.71 6.53133 56.71 6.34934C56.71 5.81154 56.5878 5.29819 56.346 4.8283C56.1043 4.35569 55.7485 3.96999 55.2921 3.6848C54.8358 3.3996 54.2926 3.25293 53.6841 3.25293C53.0757 3.25293 52.5596 3.39689 52.087 3.6848C51.6143 3.96999 51.2368 4.37198 50.9624 4.8799C50.6881 5.38511 50.5496 5.96365 50.5496 6.59651C50.5496 7.22938 50.6908 7.80792 50.9679 8.31855C51.2449 8.82919 51.6361 9.23933 52.1277 9.53267V9.52724ZM52.4754 4.80114C52.8122 4.50508 53.2169 4.35569 53.6814 4.35569C54.1459 4.35569 54.556 4.51322 54.8793 4.82287C55.1862 5.11621 55.3573 5.48832 55.3872 5.92834H51.8669C51.9511 5.46116 52.1549 5.0809 52.4754 4.80114Z"
        fill="#004BC8"
      />
      <path
        d="M57.9539 10.6191C57.2531 10.6191 56.6827 11.1895 56.6827 11.8903C56.6827 11.9012 56.6827 11.9093 56.6854 11.9175C49.6096 14.4245 40.5917 15.8396 31.0277 15.9509C30.8539 15.4566 30.3867 15.0981 29.8326 15.0981C29.2785 15.0981 28.8113 15.4566 28.6374 15.9509C19.0681 15.8423 10.0529 14.4245 2.97976 11.9175C2.97976 11.9066 2.98247 11.8984 2.98247 11.8903C2.98247 11.1895 2.41206 10.6191 1.71127 10.6191C1.01048 10.6191 0.440063 11.1895 0.440063 11.8903C0.440063 12.5911 1.01048 13.1615 1.71127 13.1615C2.11056 13.1615 2.46367 12.9713 2.69727 12.6834C9.8573 15.2203 18.9676 16.6544 28.6293 16.7658C28.7977 17.2737 29.2676 17.6404 29.8299 17.6404C30.3921 17.6404 30.8648 17.271 31.0305 16.7658C40.6894 16.6544 49.7997 15.2203 56.9625 12.6834C57.1961 12.974 57.5492 13.1615 57.9485 13.1615C58.6493 13.1615 59.2197 12.5911 59.2197 11.8903C59.2197 11.1895 58.6493 10.6191 57.9485 10.6191H57.9539Z"
        fill="#004BC8"
      />
    </svg>
  );
}
